@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,400..840;1,400..840&family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap');
:root{
  --dark-color: #271AA8;
  --primary-color: #FF6B00;
 
}
a {
  color: inherit;
  text-decoration: none;
}
body, html{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Andada Pro", serif;
}
p {
  font-size: 15px;
}
.button {
  background: linear-gradient(180deg, #271AA8, #0F0A42);
  width: max-content;
  padding: 9px 14px 9px 14px;
  border-radius: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-weight: 300;
  box-shadow: 1px 6px 5px -2px #0003;
} 
.button span{
  position: relative;
} 
.header-blk {
  width: 95%;
  margin: auto;
}
.logo {
  margin-top: 15px;
  padding-left: 30px;
}


.button.padding {
  padding: 12px 17px;
}
.menu-tab{
  /* background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/menu-bg.svg); */
  background-size: 110% 110%;
    background-position: center;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    position: absolute;
    right: 30px;
    z-index: 999;
    top: 38px;
    display: none;
}
button.menu-toggle {
  transition: 0.5s;
  border: 0px;
  background: transparent;
}
.d-flex.justify-content {
  justify-content: center;
}
 
.menu-tab:hover button.menu-toggle {
background: var(--primary-color);
}

.sub-submenu li:hover {
  color: var(--primary-color);
}
ul.submenu {
  display: flex;
  flex-wrap: wrap; 
  margin: auto;
  justify-content: space-around;
  padding: 0px 0;
  border-top: 2px solid var(--dark-color);
  margin-top: 30px;
  position: relative;
}

ul.submenu > li:hover > ul {
  display: block;
  z-index: 9;
  background: #fff;
  width: max-content;
  padding: 15px;
  top: 103%;
}

ul.sub-submenu {
  position: absolute;
  top: 100%;
}
ul.submenu > li{
  cursor: pointer;
  position: relative;
  list-style: none;
  text-transform: uppercase; 
  padding: 20px 8px;
}
ul.submenu > li:before {
  content: "";
  width: 100%;
  height: 0%;
  position: absolute;
  transition: 0.5s;
  left: 0;
  top: 0;
  background: #1b1272;
  z-index: -1;
}

ul.submenu > li:hover:before {
  height: 100%;
}

ul.submenu li:hover {
  color: #fff;
}
ul.submenu li ul li{
padding: 0 5px;
}
ul.submenu > li:hover > ul{
  list-style: none;
  text-transform: uppercase; 
  font-size: 16px; 
}
ul.submenu > li:last-child:hover > ul{ 
  right: 0;
  left: unset;
}
a:hover{
  color: inherit;
} 

.menu .logo {
  width: 80%;
  margin: auto;
}

.menu .logo img {
  width: auto;
  margin: auto;
  display: block;
  margin: 40px auto;
}

ul.submenu > li ul {
  color: #333;
  padding: 0;
  list-style: none; 
  display: none;
  left: 0;
}

ul.submenu > li ul li {
  margin-top: 9px;
  font-weight: normal;
  font-size: 14px;
}

ul.submenu ul li:hover {
  color: var(--primary-color);
}
   
 
.header { 
    border-bottom: 2px solid var(--dark-color);
}
.pd-0{
  padding: 0;
}

.main-slider .owl-nav {
  position: absolute;
}
 
.title{
  text-align: center;
  margin-bottom: 40px;
}
.title p{margin-bottom: 0;letter-spacing: 2px;color: #666666;}
.title h3{
  color: #333333;
  font-weight: 700;
  text-transform: uppercase;
}
.title h3 span{
  color: var(--primary-color);
  font-weight: 700;
  display: block;
  font-size: 43%;

  
  }
  .history img{
    display: inline;
    width: 50%;
  } 
  .history_anim img {
    transition: 0.5s;
    position: absolute;
    z-index: 99;
}

.history_anim img.left-img {
    left: 0;
    top: 0;
}

.history_anim img.right-img {
    right: 2px;
    top: 0;
}

.history_anim {
    position: relative;
    min-height: 460px;
    text-align: center;
}

.history_anim:hover img.left-img {
    left: -54%;
}
.history_anim:hover img.right-img {
    right: -54%;
}

.history_anim p {
    padding-top: 150px;
    transition: 0.5s;
}

.history_anim:hover p {
    padding-top: 0;
}

.history_anim .button {
    margin: auto;
    border-radius: 0;
    margin-top: 40px;
}
.helm{
  background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/helm-bg.png);
  background-size: 100% 100%;
  padding: 100px 0;
  display: none;
} 
.helm select {
  background: linear-gradient(180deg, #271AA8, #0F0A42);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.helm-blk img {
    width: 90%;
    border: 4px solid #fff;
    box-shadow: 0px 0px 0px 1px var(--primary-color);
    margin: auto;
    display: block;
}

.title-helm {
    width: 73%;
    margin: auto;
    display: block;
    margin-top: -20px;
    background: #fff;
    z-index: 999;
    position: relative;
    min-height: 66px;
    padding: 7px;
    border-radius: 5px;
    box-shadow: 0px 6px 18px -13px #000, 0px 1px 7px -4px #00000045;
}

.title-helm p span {
    display: block;
    text-transform: uppercase;
    font-size: 66%;
    font-weight: 400;
}

.title-helm p {
  text-align: center;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.charism{
  background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/charism.png);
  background-size: 100% 100%;
  padding-bottom: 80px;
  padding-top: 80px;
}
.charism img {
  margin-top: 36px;
}
.charism p {
  background: #271aa817;
  padding: 30px;
  margin-left: -70px;
  position: relative;
  width: 100%;
  padding-left: 80px;
}
.wid-50 {
  width: 75%;
  margin: auto;
}
.about-us {
  text-align: center;
  padding: 50px 0;
 background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/about-bg.png); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  
}
.about-us p span{
  color: var(--primary-color);
}
.about-us:before{
  content: "";
  width: 100%;
  height: 300px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/founder-tag.png);
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
}
.about-us .button {  margin: auto; 
  margin-top: -80px !important;
} 
.container-fluid.min-ht {
  position: relative;
}
 
.mar-0{
  margin: 0
}
  .button.padding.arrow {
    background: no-repeat;
    border: none;
    box-shadow: none;
}
.button.padding.arrow:before {
  display: none;
} 
 
.history{
  background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/history-bg.jpg);
  background-size: 100% 100%;
  padding: 100px 0 0;
} 

footer{
  background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/footer-img.png);
  background-size: 100% 100%;
  padding: 100px 0 50px;
  color: #fff;
  position: relative;
}
img.main-img {
  position: absolute;
  height: 350px;
  bottom: 0;
}
.quick-link{
  margin-top: 40px;
}
footer iframe{
  margin-top: 40px;
  border: 8px solid #fff;
    box-shadow: 0px 0px 5px 0px #000;
}

.copyright p {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  padding: 4px;
}
.quick-link h3 {
  font-size: 19px;
}
.pos-rel:before { 
content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/star.gif);
    right: -20px;
    bottom: 60px;
    background-size: auto 63%;
    background-repeat: no-repeat;
    background-position: bottom right;
    z-index: 9;
    transform: rotate(90deg);
}
.pos-rel{
  position: relative;
}



/* Inner Pages */

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  position: relative;
  background: url(https://webapi.entab.info/api/image/FSOLG/public/Images/breadcrumb.jpg);
  background-size: 100% ;
} 
.breadcrumb:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, #100a46d6, #23189891);
}

.breadcrumb ul li {
  margin: 7px;
}

.breadcrumb-blk h2 {
  position: relative;
  color: #fff;
  font-size: 4rem;
  text-transform: uppercase;
  text-shadow: 0 0 3px black;
}

.breadcrumb ul {
  position: absolute;
  display: flex;
  list-style: none;
  padding: 0;
  bottom: 0;
  margin-bottom: 0;
  background: var(--dark-color);
  color: #fff;
  padding: 2px 20px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
}
.innerpage {
  padding: 50px 0;
}
.innerpage .title{
  color: var(--dark-color);
}
.administration_blk {
  border: 12px solid #fff;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-bottom: 20px;
  text-align: center;
}

.administration_blk h3 {
  margin-top: 20px;
  color: var(--primary-color);
  font-size: 24px;
  text-transform: capitalize;
}

.administration_blk p {
  margin-bottom: 9px;
}
.addressbox {
  padding: 20px;
  display: flex;
  border-left: 5px solid var(--primary-color);
  margin-bottom: 30px;
  background: #fff8f3;
  border-radius: 5px;
}

.addressbox i {
  margin-right: 20px;
  font-size: 1.5rem;
  color: var(--dark-color);
}
.formation h3{
  color: var(--primary-color);
  font-size: 21px;
}
.formation h4 {
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-color);
}

.formation .formation-tab {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.formation .formation-tab > div {
  background: #fff1e7;
  width: 100%;
  padding: 40px;
  margin-left: -20px;
  box-shadow: 0px 0px 12px 3px #ddd;
}
.gallery_desc a img {
  margin-top: 20px;
  display: block;
  height: 200px;
  object-fit: contain;
  background: #e9e9e9;
  border: 2px solid #ddd;
  width: 100%;
  border: 6px solid #fff;
  box-shadow: 0px 0px 5px 0px #ddd;
}
.area-pic {
  margin-bottom: 20px;
}
.tab_select > div {
  background: #ebebeb;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 5px;
  border-left: 5px solid #271aa8;
} 

.flex-img h4 {
    text-align: center;
    font-size: 20px;
    margin: 10px 0;
    font-size: 17px;
}

.flex-img a {
    border: 1px solid #f1f1f1;
    box-shadow: 1px 20px 10px -17px #cdcdcd;
    display: block;
    border-radius: 5px;
    padding: 8px;
}

.flex-img img {
    width: 100%;
    background: #ddd;
}
.flex-img{
  margin-bottom: 20px;
}
.institute h3, .sub_title {
    color: var(--primary-color);
    font-size: 20px;
    margin-bottom: 20px;
}

.tab-item.active {
  background:  #271aa8;
  color: #fff;
  border-left: 5px solid #ff6b08;
}
ul.communities li:after {
  position: absolute;
  top: 0;
  left: -25px;
  content: ' \F270 ';
  width: 100%;
  font-family: 'bootstrap-icons';
  font-size: 16px;
  color: #bc0557;
} 

ul.communities li {
    position: relative;
    list-style: none;
    width: 25%;
}

ul.communities {
    display: flex;
    flex-wrap: wrap;
}
.flowerboximg {
  border: 1px solid #efefef;
  margin-bottom: 20px;
  text-align: center;
  padding: 12px 12px 0;
  border-radius: 5px;
  box-shadow: -2px 6px 17px -6px #c7c7c7;
}

.flowerboximg img {
  margin-bottom: 20px;
}


.row.tabs-dynamic select, .row.tabs-dynamic input {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #000;
  padding: 3px 0;
  background: transparent;
  color: #000;
  font-size: 18px;
}
.row.tabs-dynamic + .discription {
text-align: center;
margin: 20px 0;
}

.row.tabs-dynamic {
padding: 10px;
margin-bottom: 20px;
border-radius: 5px;
background: #dbdbdb;
 
}
.sub-gallery .zoompic img{
  border: 6px solid #fff;
  box-shadow: 0px 0px 8px 0px #bbb;
  margin-bottom: 20px;
}
.count-val  p {
  margin-bottom: 0; 
  font-size: 18px;
}
 
.gal_new img {
  background: #efefef;
  height: 200px;
  object-fit: contain;
  width: 100%;
}

.gal_new {
  padding: 5px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  position: relative;
}

.gal_new .gal-content h4 {
  font-size: 18px;
  color: #962734;
}

.gal_new .gal-content {
  padding: 17px 0;
  width: 91%;
  text-align: center;
  margin: auto;
}

.gal_new .gal-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 48px;
}

h6.date {
  font-weight: 400;
  text-align: left;
  font-size: 13px;
  margin-bottom: 0;
}

h6.date span {
  float: right;
  font-size: 17px;
  background: var(--dark-color);
  color: #fff;
  padding: 5px;
  vertical-align: middle;
  border-radius: 3px;
  line-height: 0;
}

.total-img {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  background: var(--primary-color);
  color: #fff;
  padding: 0px 10px 5px;
  border-radius: 0 5px 5px 0px;
  box-shadow: 3px 4px 5px -3px #3e3e3e;
}

.total-img i {
  font-size: 15px;
}

.total-img p {
  margin-bottom: 0;
}

.total-img p {
  min-height: auto !important;
}

/* magnifier */

.magnify-modal {
position: fixed !important;
z-index: 999999999 !important;
padding-top: 10px !important;
left: 0 !important;
top: 0 !important;
width: 100% !important;
height: 100% !important;
overflow: auto !important;
background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
float: right;
position: relative;
z-index: 9
}

.magnify-stage {
position: absolute;
top: 40px;
right: 10px;
bottom: 40px;
left: 10px;
z-index: 1;
background: #0000000a;
overflow: hidden
}

.magnify-image {
position: relative;
display: inline-block;
border: 5px solid #fff
}

.magnify-image.image-ready {
max-width: 100%;
max-height: 100%
}

.magnify-footer {
position: absolute;
bottom: 0;
z-index: 2;
width: 100%;
height: 40px;
color: #fff;
text-align: center
}

.magnify-footer .magnify-toolbar {
display: inline-block
}

.magnify-button {
display: inline-block;
width: 40px;
height: 40px;
-webkit-box-sizing: border-box;
box-sizing: border-box;
margin: 0;
padding: 10px;
font-size: 16px;
color: #fff;
text-align: center;
border-width: 0;
border-radius: 0;
cursor: pointer;
outline: none;
background: #055a91;
}

.magnify-button:hover {
color: #fff;
background-color: #e00000
}

.magnify-button-close:hover {
background-color: #e00000
}

.magnify-loader {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 2;
text-align: center;
background-color: rgba(0, 0, 0, 0.3);
color: #333
}

.magnify-loader::before {
content: '';
display: inline-block;
position: relative;
width: 36px;
height: 36px;
-webkit-box-sizing: border-box;
box-sizing: border-box;
border-width: 5px;
border-style: solid;
border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
border-radius: 100%;
-webkit-animation: magnifyLoading 1s infinite linear;
animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
content: '';
display: inline-block;
width: 0;
height: 100%;
vertical-align: middle;
overflow: hidden
}

@-webkit-keyframes magnifyLoading {
0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
}

100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
}
}

@keyframes magnifyLoading {
0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
}

100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
}
}


/* magnifier */
.eventCount .event-blk {
box-shadow: 0px 0px 5px 0px #ddd;
margin-top: 20px;
padding: 17px;
position: relative;
}

.eventCount .event-blk img {
height: 300px;
background: #ededed;
width: 100%;
object-fit: contain;
}

.event-blk {}

.event-blk h6.date {
position: absolute;
left: 0;
top: 0;
color: #fff;
background: var(--light-gradient);
padding: 8px 10px;
box-shadow: 4px 5px 6px -6px #000;
}


.event-blk .inr-event-content {
padding-top: 20px;
}

.event-blk .inr-event-content h3 {
font-size: 20px;
text-align: center;
color: #972734;
}

.event-blk .inr-event-content {
text-align: center;
}

.event-blk .inr-event-content p {
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
overflow: hidden;
}

.event-blk .inr-event-content h6.loc {
width: 100%;
text-align: left;
font-size: 14px;
}

.event-blk .inr-event-content h6.loc span {
float: right;
}
select option {
color: #18305e;
}
.inr-news-blk {
border-left: 6px solid #962734;
padding: 20px;
margin-top: 20px;
background: #fff;
box-shadow: 0px 20px 15px -29px #000;
border-radius: 5px;
transition: 0.5s
} 

.inr-news-content h6.loc {
color: #7a7a7a;
}
.inr-news-blk:hover {
box-shadow: 0px 1px 15px -12px #000;
}

.video-gallery a, .video-gallery {
  display: flex;
  align-items: center;
}

.video-gallery a img, .video-gallery img {
  margin-right: 14px;
}

.video-gallery {
  margin-top: 20px;
  background: #fff;
  padding: 14px 10px;
  border-radius: 5px;
  border: 1px solid #efefef;
  box-shadow: 1px 20px 18px -20px #cbcbcb;
}

.video-gallery h3 {
  font-size: 19px;
} 

.row.inner_cont .chooseyear {margin-left: auto;display: block;width: max-content;}

.row.inner_cont .chooseyear select {
  min-width: 100px;
  padding: 5px 0;
  border: 0px;
  border-bottom: 1px solid #ddd;
  font-size: 19px;
}
.inr-news-blk.acheivement_blk img {
  height: 416px;
  margin-right: 30px;
  max-width: 100%;
} 
.inr-news-blk.acheivement_blk h6 {
  font-weight: 600;
}
.inr-news-blk.acheivement_blk{
  display: flex;
}
@media(max-width: 1400px){
  ul.submenu > li { 
    padding: 20px 6px;
}
  ul.submenu > li { 
    font-size: 14px;
}
.logo {
  margin-top: 23px;
  padding-left: 0;
}
img.main-img { 
  height: 300px; 
}
.wid-50 {
  width: 100%;
  margin: auto;
}
footer {  
  padding: 80px 0 30px; 
  position: relative;
}
.online-reg img {
  width: 160px;
}
.button.padding {
  padding: 9px 14px;
}
.menu-tab { 
  top: 25px;
  right: 20px
  
} 
  p {
    font-size: 14px;
} 

 

}
@media(max-width: 1200px){
  .formation .formation-tab {
    flex-direction: column;
}

.formation .formation-tab > div {
    margin-left: 0;
}
  .history_anim img{
    position: static;
  }
  .history_anim:hover img.left-img, .history_anim:hover img.right-img{
    left: unset;
    right: unset;
  }
  .history_anim p{
    padding-top: 0;
    margin-top: 20px;
  }
  .logo img.img-fluid {
    margin: 20px auto;
    display: block;
    padding-left: 0;
}
  
.header-blk {
  width: 98%;
  margin: auto;
}   
.helm-blk{
  margin-bottom: 30px;
}
}
 

@media(max-width: 991px){
  ul.communities li { 
    width: 50%;
}
  ul.submenu > li ul {
    padding-bottom: 0 !important;
    position: static;
    width: 100% !important;
    background: #ededed !important;
}


ul.submenu > li ul li {
    margin: 0;
}
  
.header{
  position: relative;
}

.menu .submenu {
    width: 100%;
    position: absolute;
    z-index: 9;
    background: #fff;
    top: 100%;
    margin-top: 0;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px #ddd;
    border-radius: 0 0 5px 5px;
}

.menu .submenu li {
    width: 100%;
    padding: 10px 0;
}
  button.menu-toggle {
    background: var(--dark-color);
    color: #fff;
    border-radius: 5px;
    padding: 2px 7px 0;
    font-size: 19px;
    margin-top: 20px;
}
  .menu-tab{
    display: block;
  }
  .menu ul.submenu { 
    width: 90%; 
}
.submenu >li{
  width: 32%
}
footer iframe { 
  width: 50%;
}
 footer {
  background: var(--dark-color); 
}
img.main-img{
  right: 0;
}
.breadcrumb-blk h2 { 
    font-size: 2.5rem; 
}
.breadcrumb ul li {
    margin: 7px;
    width: max-content;
}
}
@media(max-width: 767px){
    ul.communities li { 
    width: 100%;
}
.breadcrumb-blk h2 { 
    font-size: 2rem; 
}
  .charism img {
    margin-top: 36px;
    width: 100%;
}
.charism p{
  margin-left: 0;
  padding: 20px
}
  
.logo img.img-fluid {
  margin: 20px auto;
  display: block;
  width: 74%;
} 
.charism {   
  background-size: 100% auto;
}
.helm { 
  background-size: auto 100%;
  padding: 100px 0;
  background-position: right;
}
.pos-rel:before {
  content: "";
  width: 50%; 
  left: 0;
  bottom: 0;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  z-index: 9;
}
}
@media(max-width : 600px){
  .inr-news-blk.acheivement_blk img {
    width: 100%;
    object-fit: contain;
    max-width: initial;
    margin-bottom: 20px;
}
.inr-news-blk.acheivement_blk { 
  flex-direction: column;
}
  button.menu-toggle { 
    margin-top: 0px;
}
.about-us .button { 
  max-width: 100%;
}
  .history img {
    display: inline;
    width: 100%;
}
  footer iframe{
    width: 100%;
  }
  img.main-img{
    position: static;
  }
.online-reg img {
  width: 133px;
}
  .logo img.img-fluid {
    margin: 0px 0 10px;
    display: block;
    width: 81%;
}
  .submenu >li {
    width: 50%;
}
.menu .logo {
  width: 100% !important;} 

}
@media(max-width: 400px){
  .submenu >li {
    width: 100%;
}
}

